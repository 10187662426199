/* POPPINS */
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

/* OXANIUM */

@font-face {
  font-family: 'Oxanium';
  font-weight: 300;
  src: url('../fonts/Oxanium/Oxanium-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Oxanium';
  font-weight: 400;
  src: url('../fonts/Oxanium/Oxanium-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oxanium';
  font-weight: 600;
  src: url('../fonts/Oxanium/Oxanium-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Oxanium';
  font-weight: 700;
  src: url('../fonts/Oxanium/Oxanium-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Oxanium';
  font-weight: 800;
  src: url('../fonts/Oxanium/Oxanium-ExtraBold.ttf') format('truetype');
}

/* NEBULA */
@font-face {
  font-family: 'Nebula';
  font-weight: 400;
  src: url('../fonts/Nebula/Nebula-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Nebula';
  font-weight: 700;
  src: url('../fonts/Nebula/Nebula-Bold.otf') format('opentype');
}
