.rc-tooltip {
  width: min-content !important;
  z-index: 9999999;
  opacity: 1;
}

.rc-tooltip.rc-tooltip-fade-appear,
.rc-tooltip.rc-tooltip-fade-enter {
  opacity: 0;
}
.rc-tooltip.rc-tooltip-fade-enter,
.rc-tooltip.rc-tooltip-fade-leave {
  display: block;
}
.rc-tooltip-fade-enter,
.rc-tooltip-fade-appear {
  opacity: 0;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
}
.rc-tooltip-fade-leave {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
}
.rc-tooltip-fade-enter.rc-tooltip-fade-enter-active,
.rc-tooltip-fade-appear.rc-tooltip-fade-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-fade-leave.rc-tooltip-fade-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* linkGroup findPatient */
body .rc-tooltip-linkGroup-find-patient .rc-tooltip-arrow {
  opacity: 1 !important;
  padding: 0 !important;
  left: 20%;
  border-bottom-color: var(--shark-second-color);
}
body .rc-tooltip-linkGroup-find-patient .rc-tooltip-inner {
  opacity: 1 !important;
  padding: 0 !important;
}

/* linkGroup Diary*/
body .rc-tooltip-linkGroup-diary .rc-tooltip-arrow {
  opacity: 1 !important;
  padding: 0 !important;
  left: 17%;
  border-bottom-color: var(--shark-second-color);
}
body .rc-tooltip-linkGroup-diary .rc-tooltip-inner {
  opacity: 1 !important;
  padding: 0 !important;
}

/* expandedSearchBar */
body .rc-tooltip-expandedSearchBar .rc-tooltip-arrow {
  opacity: 1 !important;
  padding: 0 !important;
  border-bottom-color: #3a3a3a !important;
}
body .rc-tooltip-expandedSearchBar .rc-tooltip-inner {
  opacity: 1 !important;
  padding: 0 !important;
}

// mainMenuContent
body .rc-tooltip-right-background-gray-150 {
  .rc-tooltip-inner {
    background: var(--gray-150-color) !important;
  }
  .rc-tooltip-arrow {
    border-right-color: var(--gray-150-color) !important;
  }
}

body .rc-tooltip-left-background-gray-150 {
  .rc-tooltip-inner {
    background: var(--gray-150-color) !important;
  }
  .rc-tooltip-arrow {
    border-left-color: var(--gray-150-color) !important;
  }
}

body .rc-tooltip-top-background-gray-150 {
  .rc-tooltip-inner {
    background: var(--gray-150-color) !important;
  }
  .rc-tooltip-arrow {
    border-top-color: var(--gray-150-color) !important;
  }
}

body .rc-tooltip-bottom-background-gray-150 {
  .rc-tooltip-inner {
    background: var(--gray-150-color) !important;
  }
  .rc-tooltip-arrow {
    border-bottom-color: var(--gray-150-color) !important;
  }
}
